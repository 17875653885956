import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from 'react-icons/io';

import Styles from './index.module.css'
import Cesium1 from '../../components/cesium/cesium1dis'
import Cesium2 from '../../components/cesium/cesium2dis'

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/redqinc/',
    tooltip: 'Facebook',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/redqinc/',
    tooltip: 'Instagram',
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/redqinc',
    tooltip: 'Twitter',
  },
  {
    icon: <IoLogoLinkedin />,
    url: 'https://www.linkedin.com/company/redqinc/',
    tooltip: 'Linked In',
  },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = (props) => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
<div className={Styles.tabWrap}>


    <input id="TAB-01" type="radio" name="TAB" className={Styles.tabSwitch} checked="checked" />
    <label className={Styles.tabLabel} for="TAB-01">基本表示</label>

    <div className={Styles.tabContent}>
        <Cesium1
          no={props.no}
          title={props.title}
          date={props.date}
          sdgs_no={props.sdgs_no}
          sdgs_main={props.sdgs_main}
          sdgs_subcat={props.sdgs_subcat}
          kou_tisou_pt={props.kou_tisou_pt}
          kou_tisou_chp={props.kou_tisou_chp}
          kou_tisou_sec={props.kou_tisou_sec}
          kou_tisou_sbsec={props.kou_tisou_sbsec}
          kou_tisou_title={props.kou_tisou_title}
          kou_titan_pt={props.kou_titan_pt}
          kou_titan_chp={props.kou_titan_chp}
          kou_titan_sec={props.kou_titan_sec}
          kou_titan_sbsec={props.kou_titan_sbsec}
          kou_titan_title={props.kou_titan_title}
          description={props.description}
          height_name={props.height_name}
          height_origin={props.height_origin}
          height_origin_note={props.height_origin_note}
          height_origin_url_name={props.height_origin_url_name}
          height_origin_url_name2={props.height_origin_url_name2}
          height_origin_url={props.height_origin_url}
          height_origin_url2={props.height_origin_url2}
          color_name={props.color_name}
          color_origin_note={props.color_origin_note}
          color_origin={props.color_origin}
          color_origin_url_name={props.color_origin_url_name}
          color_origin_url2_name={props.color_origin_url2_name}
          color_origin_url={props.color_origin_url}
          color_origin_url2={props.color_origin_url2}
          sokuchi={props.sokuchi}
          mesh_size={props.mesh_size}
          year_display={props.year_display}
          honbun={props.honbun}
          chyusyaku={props.chyusyaku}
          hosoku={props.hosoku}
          kml_file={props.kml_file}
          hanrei1_name={props.hanrei1_name}
          hanrei1_color={props.hanrei1_color}
          hanrei2_name={props.hanrei2_name}
          hanrei2_color={props.hanrei2_color}
          hanrei3_name={props.hanrei3_name}
          hanrei3_color={props.hanrei3_color}
          hanrei4_name={props.hanrei4_name}
          hanrei4_color={props.hanrei4_color}
          height_ratio={props.height_ratio}

        />
    </div>

    <input id="TAB-02" type="radio" name="TAB" className={Styles.tabSwitch} /><label className={Styles.tabLabel} for="TAB-02">他の都市と比較する</label>
    <div className={Styles.tabContent}>
      <Cesium2
          no={props.no}
          title={props.title}
          date={props.date}
          sdgs_no={props.sdgs_no}
          sdgs_main={props.sdgs_main}
          sdgs_subcat={props.sdgs_subcat}
          kou_tisou_pt={props.kou_tisou_pt}
          kou_tisou_chp={props.kou_tisou_chp}
          kou_tisou_sec={props.kou_tisou_sec}
          kou_tisou_sbsec={props.kou_tisou_sbsec}
          kou_tisou_title={props.kou_tisou_title}
          kou_titan_pt={props.kou_titan_pt}
          kou_titan_chp={props.kou_titan_chp}
          kou_titan_sec={props.kou_titan_sec}
          kou_titan_sbsec={props.kou_titan_sbsec}
          kou_titan_title={props.kou_titan_title}
          description={props.description}
          height_name={props.height_name}
          height_origin={props.height_origin}
          height_origin_note={props.height_origin_note}
          height_origin_url_name={props.height_origin_url_name}
          height_origin_url_name2={props.height_origin_url_name2}
          height_origin_url={props.height_origin_url}
          height_origin_url2={props.height_origin_url2}
          color_name={props.color_name}
          color_origin_note={props.color_origin_note}
          color_origin={props.color_origin}
          color_origin_url_name={props.color_origin_url_name}
          color_origin_url2_name={props.color_origin_url2_name}
          color_origin_url={props.color_origin_url}
          color_origin_url2={props.color_origin_url2}
          sokuchi={props.sokuchi}
          mesh_size={props.mesh_size}
          year_display={props.year_display}
          honbun={props.honbun}
          chyusyaku={props.chyusyaku}
          hosoku={props.hosoku}
          kml_file={props.kml_file}
          hanrei1_name={props.hanrei1_name}
          hanrei1_color={props.hanrei1_color}
          hanrei2_name={props.hanrei2_name}
          hanrei2_color={props.hanrei2_color}
          hanrei3_name={props.hanrei3_name}
          hanrei3_color={props.hanrei3_color}
          hanrei4_name={props.hanrei4_name}
          hanrei4_color={props.hanrei4_color}
          height_ratio={props.height_ratio}
 />
    </div>
</div>  );
};

export default About;
